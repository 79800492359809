$primary: #361f12;
$secondary: #7a4628;
$primary-darker: #29170d;
$primary-darker-transparent: rgba(41, 23, 13, 0.75);
$gray: #2b2a29;
$gray-darker: #1b1b1b;
$gray-lighter: #f0f0f0;
$gray-light2: #ebebeb;
$gray-light: #f5f5f5;
$gray-light-transparent: rgba(245, 245, 245, 0.75);

@import "node_modules/bootstrap/scss/bootstrap";

$theme-colors: (
        "primary":    $primary,
        //"secondary":  $secondary,
        //"success":    $success,
        //"info":       $info,
        //"warning":    $warning,
        //"danger":     $danger,
        "light":      $gray-light,
        //"dark":       $dark
);
