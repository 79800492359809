#header {
  z-index: 999;
  width: 100%;
  height: 100vh;
  background: url("/img/home-bkd.jpg") no-repeat center;
  background-size: cover;
  @include media-breakpoint-down(lg) {
    align-items: stretch !important;
  }

  #nav-top {
    text-align: center;

    .logoContainer {
      margin-bottom: 1rem;
      transition: all 150ms ease-in-out;
      @include media-breakpoint-down(lg) {
        margin-top: 2rem;
      }

      img {
        @include media-breakpoint-down(sm) {
          max-width: 80%;
        }
      }
    }

    ul {
      margin: 0;
      padding: 0;

      li {
        display: inline-block;
        padding: 1rem 2rem;
        font-size: 1.5rem;
        position: relative;
        text-align: center;

        @include media-breakpoint-down(lg) {
          padding: 0.5rem 2rem;
        }

        a {
          background-color: $gray-light-transparent;
          position: relative;
          color: $primary-darker;
          transition: all .2s cubic-bezier(.99, .01, .24, .99);
          padding: 1.5rem 2rem;

          &:hover {
            background-color: $primary-darker-transparent;
            color: $gray-light;
          }

          @include media-breakpoint-down(xl) {
            padding: 0.75rem 1rem;
          }
        }

      }
    }
  }
}
