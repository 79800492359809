//Colours
$green: #2ecc71;
$red: #e74c3c;
$blue: #3498db;
$yellow: #f1c40f;
$purple: #8e44ad;
$turquoise: #1abc9c;

// Basic Button Style
.btn {
  box-sizing: border-box;
  appearance: none;
  background-color: transparent;
  border: 2px solid $red;
  border-radius: 0.6em;
  color: $red;
  cursor: pointer;
  display: flex;
  align-self: center;
  font-size: 1rem;
  line-height: 1;
  margin: 20px;
  padding: 1.2em 2.8em;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;

  &:hover,
  &:focus {
    color: #fff;
    outline: 0;
  }
}

//BUTTON 1
.btn-fadein {
  transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
  &:hover {
    box-shadow: 0 0 40px 40px $red inset;
  }
}

//BUTTON 2
.btn-stripped {
  border-radius: 3em;
  border-color: $turquoise;
  color: #fff;
  background: {
    image: linear-gradient(to right,
            transparentize($turquoise, 0.4),
            transparentize($turquoise, 0.4) 5%,
            $turquoise 5%,
            $turquoise 10%,
            transparentize($turquoise, 0.4) 10%,
            transparentize($turquoise, 0.4) 15%,
            $turquoise 15%,
            $turquoise 20%,
            transparentize($turquoise, 0.4) 20%,
            transparentize($turquoise, 0.4) 25%,
            $turquoise 25%,
            $turquoise 30%,
            transparentize($turquoise, 0.4) 30%,
            transparentize($turquoise, 0.4) 35%,
            $turquoise 35%,
            $turquoise 40%,
            transparentize($turquoise, 0.4) 40%,
            transparentize($turquoise, 0.4) 45%,
            $turquoise 45%,
            $turquoise 50%,
            transparentize($turquoise, 0.4) 50%,
            transparentize($turquoise, 0.4) 55%,
            $turquoise 55%,
            $turquoise 60%,
            transparentize($turquoise, 0.4) 60%,
            transparentize($turquoise, 0.4) 65%,
            $turquoise 65%,
            $turquoise 70%,
            transparentize($turquoise, 0.4) 70%,
            transparentize($turquoise, 0.4) 75%,
            $turquoise 75%,
            $turquoise 80%,
            transparentize($turquoise, 0.4) 80%,
            transparentize($turquoise, 0.4) 85%,
            $turquoise 85%,
            $turquoise 90%,
            transparentize($turquoise, 0.4) 90%,
            transparentize($turquoise, 0.4) 95%,
            $turquoise 95%,
            $turquoise 100%);
    position: 0 0;
    size: 100%;
  }
  transition: background 300ms ease-in-out;

  &:hover {
    background-position: 100px;
  }
}

//BUTTON 3
.btn-fadeout {
  border-color: $primary;
  color: #fff;
  box-shadow: 0 0 40px 40px $primary inset, 0 0 0 0 $primary;
  transition: all 150ms ease-in-out;

  &:hover {
    box-shadow: 0 0 10px 0 $primary inset, 0 0 10px 4px $primary;
  }
}

// BUTTON 4
.btn-slideright {
   background: $primary;
  border-color: $primary-darker;
  color: white;
  background: {
    image: linear-gradient(45deg,$primary-darker 50%, transparent 50%);
    position: 100%;
    size: 400%;
  }
  transition: background 300ms ease-in-out;

  &:hover {
    background-position: 0;
  }
}

//BUTTON 5
.btn-explode {
  border-color: $primary-darker;
  // border: 0;
  border-radius: 0;
  color: $primary-darker;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: color 150ms ease-in-out;

  &:after {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 100%;
    background: $primary;
    z-index: -1;
    transition: width 150ms ease-in-out;
  }

  &:hover {
    color: $gray-light;
    &:after {
      width: 110%;
    }
  }
}

.bg-dark {
  .btn-explode {
    border-color: $gray-light;
    color: $gray-light;

    &:after {
      background: $gray-light;
    }

    &:hover {
      color: $secondary;
    }
  }
}
