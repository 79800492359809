.arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;

  border-top: 20px solid $primary;

  &.primary-darker {
    border-top-color: $primary-darker;
  }

  &.gray-light {
    border-top-color: $gray-light;
  }

  &.dark {
    border-top-color: $dark;
  }
}