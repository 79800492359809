h1, h2, h3 {
  text-transform: uppercase;
  text-align: center;
}

h1 {
  font-size: 3.5rem;
  font-family: 'Comfortaa', cursive;
}

h2 {
  font-size: 2rem;
  padding: 2rem 3rem;
  position: relative;
  z-index: 0;
  font-family: 'Comfortaa', cursive;
  @include media-breakpoint-down(sm) {
    font-size: 1.5rem;
    padding: 1rem;
  }
}

h3 {
  font-size: 1.7rem;
  padding: 1.5rem 2rem;
  font-family: 'Comfortaa', cursive;
  @include media-breakpoint-down(sm) {
    font-size: 1.2rem;
    padding: 0.5rem;
  }
}

a {
  text-decoration: underline;
  color: $primary-darker;
  font-weight: bold;
  transition: color 300ms ease-in-out;

  &:hover {
    color: $primary;
  }
}

.bg-dark {
  a {
    color: $gray-light;

    &:hover {
      color: $secondary;
    }
  }
}