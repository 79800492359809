@import "./_settings.scss";
@import "D:/HTTP/restaurant-mordu/static/sass/helpers/_arrow.scss";
@import "D:/HTTP/restaurant-mordu/static/sass/helpers/_buttons.scss";
@import "D:/HTTP/restaurant-mordu/static/sass/helpers/_fonts.scss";
@import "D:/HTTP/restaurant-mordu/static/sass/helpers/_hover.scss";
@import "D:/HTTP/restaurant-mordu/static/sass/helpers/_mixins.scss";
@import "D:/HTTP/restaurant-mordu/static/sass/helpers/_text.scss";
@import "D:/HTTP/restaurant-mordu/static/sass/helpers/_transition.scss";
@import "D:/HTTP/restaurant-mordu/static/sass/helpers/_visibility.scss";

@import "D:/HTTP/restaurant-mordu/static/sass/components/_footer.scss";
@import "D:/HTTP/restaurant-mordu/static/sass/components/_nav.scss";

@import "D:/HTTP/restaurant-mordu/static/sass/pages/_index.scss";
@import "D:/HTTP/restaurant-mordu/static/sass/pages/_terms.scss";


* {
  font-family: 'Work Sans', sans-serif;
}

html {
  font-size: 16px;
}

body {
  transition: all 200ms ease-in-out;
  background: $primary;
  color: $gray-light;
}

.bg-light {
  color: $primary-darker;
}